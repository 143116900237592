import { OnboardHeaderComponent } from '../OnboardHeader/OnboardHeaderComponent';
import './BeaconOnlyBrandComponent.scss';
import { BeaconOnlyBrandModel as model } from './model';
import {
	ITimeLineStepComponentProps,
	TimeLineComponent,
	TimeLineStepComponent
} from '../TimeLine/TimeLineComponent';
import { useEffect, useState } from 'react';
import { BeaconOnlyDesktopSteps } from './TimeLineConfigDesktop';
import { BeaconOnlyMobileSteps } from './TimeLineConfigMobile';
import { useWindowSize } from '../../../hooks/useWindowSize';
const HeaderContent = () => {
	return (
		<div className='beacon-only-brand-header-text-container'>
			<span className='beacon-only-brand-header-text'>{model.headerPrevText}</span>{' '}
			<a href={`mailto:${model.headerEmailLink}`} target='_blank' rel='noopener noreferrer'>
				{model.headerEmailLink}
			</a>{' '}
			<span className='beacon-only-brand-header-text'>{model.headerNextText}</span>
		</div>
	);
};

export const BeaconOnlyBrandComponent = () => {
	const mobileBreakpoint = 744;
	const { width } = useWindowSize();
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [steps, setSteps] = useState<ITimeLineStepComponentProps[]>([]);
	useEffect(() => {
		setIsMobile(width < mobileBreakpoint);
	}, [width]);
	useEffect(() => {
		setSteps(isMobile ? BeaconOnlyMobileSteps : BeaconOnlyDesktopSteps);
	}, [isMobile]);

	return (
		<div className='beacon-only-brand-container'>
			<OnboardHeaderComponent title={model.title} children={<HeaderContent />} />
			<div className='beacon-only-timeline-content-container'>
				<TimeLineComponent classes='beacon-only'>
					{steps.map((step, index) => (
						<div className='timeline-step' key={index}>
							<TimeLineStepComponent {...step} />
						</div>
					))}
				</TimeLineComponent>
			</div>
		</div>
	);
};
