import { FC } from 'react';
import './userMenuItemComponent.scss';
import user from '../../../assets/images/user.svg';
export interface IUserMenuItemProps {
	name: string;
	icon?: string;
	subTitle?: string;
	onClick: () => void;
}

export const UserMenuItemComponent: FC<IUserMenuItemProps> = ({
	name,
	onClick,
	icon,
	subTitle
}) => {
	const profileNameItem = 'View Your Profile';
	return (
		<button className='user-menu-item' onClick={onClick}>
			<div className='user-menu-item-text'>
				{subTitle === profileNameItem ? (
					<img src={user} className='user-icon-edge-case' alt='user' />
				) : (
					<i className={icon + ' icon'} />
				)}
				<div className='user-item-text-container'>
					<span className='text'>{name}</span>
					{subTitle && <span className='sub-text'>{subTitle}</span>}
				</div>
			</div>
		</button>
	);
};
