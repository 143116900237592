import { FC, Fragment } from 'react';
import './EngagementChartComponent.scss';
import { VerticalBarChartComponent } from '../../UI/Charts/VerticalBarChartComponent/VerticalBarChartComponent';
enum TextPosition {
	TOP,
	BOTTOM
}
const ChartCardComponent: FC<{
	children: any;
	textPosition: TextPosition;
	legend: string;
	title: string;
	metric?: string;
}> = ({ children, textPosition, title, legend, metric }) => {
	return (
		<div className='chart-card-container'>
			<div className='chart-card-title-container'>
				<span className='chart-title'>{title}</span>
			</div>
			{textPosition === TextPosition.TOP && (
				<div className='chart-card-text-container'>
					<span className='chart-text'>{legend}</span>
				</div>
			)}
			<div className='chart-content'>{children}</div>
			{textPosition === TextPosition.BOTTOM && (
				<div className='chart-card-text-container'>
					<span className='bottom-text'>{legend}</span>
				</div>
			)}
			{metric && (
				<div className='chart-card-metric-container'>
					<span className='chart-metric'>{metric}</span>
				</div>
			)}
		</div>
	);
};
interface IEngagementChartComponentProps {
	title: string;
	data: any;
	legend: string;
	metric?: string;
}
export const EngagementChartComponent: FC<IEngagementChartComponentProps> = ({
	title,
	data,
	legend,
	metric
}) => {
	return (
		<Fragment>
			<ChartCardComponent
				textPosition={TextPosition.TOP}
				title={title}
				legend={legend}
				metric={metric}
			>
				<VerticalBarChartComponent data={data} />
			</ChartCardComponent>
		</Fragment>
	);
};
