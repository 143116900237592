import { useState, useEffect, FC, useCallback } from 'react';
import {
	IngredientCategory,
	IngredientsTemplate,
	LineTableComponent,
	Purporse
} from '../../../common/view-model';
import { ButtonComponent, IButtonComponentProps } from '../../UI/ButtonComponent/ButtonComponent';
import './NewDetailedTemplateComparisonComponent.scss';
import { Pagination, Swiper as ISwiper } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { APP_NAME } from '../../../common/constants';
import {
	getCategoryDropDownOptions,
	getIngredientsLength,
	mapPurposes,
	sortIngredientsTableView,
	toTitleCase
} from '../../../helpers/ingredientTemplateHelpers';
import { IngredientFilterActions } from '../../../redux/ingredientFilter/IngredientFilterActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/AppReducer';
import FilterIngredientTemplateComponent from '../../onbording/FilterIngredientTemplate/FilterIngredientTemplateComponent';

interface DetailViewTemplateComparisonProps {
	viewMode: 'list' | 'table';
	templates: IngredientsTemplate[] | undefined;
	currentTab: number;
	isMobile: boolean;
	isEdit: boolean;
	previewTab: number;
	mapIngredientFilterOptions: (options: IngredientCategory[]) => void;
}

interface Usage {
	[key: string]: string[];
}
export const ViewTemplateComparisonComponent: FC<DetailViewTemplateComparisonProps> = ({
	viewMode,
	templates,
	currentTab,
	isMobile,
	previewTab,
	isEdit,
	mapIngredientFilterOptions
}) => {
	let tab = currentTab;
	const allCategoriesText = 'All';
	const dispatch = useDispatch();
	const currentFilter = useSelector(
		(state: RootState) => state.appState.ingredientFilterState?.selectedFilter
	);
	const filterTemplateOptions = useSelector(
		(state: RootState) => state.appState.ingredientFilterState?.filterOptions
	);
	const [showSpecificToTemplate, setShowSpecificToTemplate] = useState(false);
	const [openFilter, setOpenFilter] = useState(false);
	const [mappedPurposes, setMappedPurposes] = useState<Purporse[] | undefined>(
		mapPurposes(tab, templates, showSpecificToTemplate, viewMode)
	);
	const [tableIngredients, setTableIngredients] = useState<LineTableComponent[] | undefined>(
		sortIngredientsTableView(mappedPurposes!, currentFilter)
	);

	const [listIngredients, setListIngredients] = useState<Usage>();
	const [sortedKeys, setSortedKeys] = useState<string[]>();
	const clearSelectedFilter = useCallback(() => {
		dispatch(IngredientFilterActions.setIngredientFilter(undefined));
	}, [dispatch]);
	const handleCategoryFilterClick = useCallback(() => {
		if (filterTemplateOptions && filterTemplateOptions?.length > 2) {
			setOpenFilter(!openFilter);
		}
	}, [filterTemplateOptions, openFilter]);
	useEffect(() => {
		tab = currentTab;
		if (!isEdit && previewTab !== -1) {
			tab = previewTab;
		}
		setMappedPurposes(mapPurposes(tab, templates, showSpecificToTemplate, viewMode));
	}, [templates, currentTab, showSpecificToTemplate, viewMode, previewTab]);

	useEffect(() => {
		if (mappedPurposes) {
			setTableIngredients(sortIngredientsTableView(mappedPurposes, currentFilter));
		}
	}, [mappedPurposes, currentFilter]);
	useEffect(() => {
		const usageMap: Usage = {};
		tableIngredients?.forEach(ingredient => {
			ingredient.usages?.forEach(usage => {
				if (usage.name) {
					const normaliceUsage = usage.name.toLowerCase();
					if (!usageMap[normaliceUsage]) {
						usageMap[normaliceUsage] = [];
					}
					if (!usageMap[normaliceUsage].includes(ingredient.displayName)) {
						usageMap[normaliceUsage].push(ingredient.displayName);
					}
				}
			});
		});
		const sortedUsage = Object.keys(usageMap).sort();
		setSortedKeys(sortedUsage);
		setListIngredients(usageMap);
	}, [tableIngredients]);
	useEffect(() => {
		setOpenFilter(false);
		clearSelectedFilter();
		const options = getCategoryDropDownOptions(currentTab, mappedPurposes);
		mapIngredientFilterOptions(options[currentTab]);
	}, [clearSelectedFilter, currentTab, mapIngredientFilterOptions, mappedPurposes]);
	if (!isEdit && previewTab !== -1) {
		tab = previewTab;
	}
	return (
		<>
			{mappedPurposes &&
				mappedPurposes.length > 0 &&
				(viewMode === 'table' ? (
					<div className='view-template-comparison-profile'>
						<div className='sentence-container-profile'>
							<span className='disclaimer-sentence'>{`*Ingredient is banned if genetically engineered. If a product has this ingredient and does not have a Non-GMO and/or Organic certification, ${APP_NAME} will not show the product.`}</span>
						</div>
						<div className={'table-container blue'} onScroll={() => setOpenFilter(false)}>
							<table className={`table ${isMobile ? 'table-striped' : ''}`}>
								<thead>
									<tr className='blue'>
										<th scope='col'>Ingredient</th>
										<th scope='col'>Usage</th>
										<th onMouseLeave={() => setOpenFilter(false)}>
											{' '}
											<div className='category-table-filter' onClick={handleCategoryFilterClick}>
												<span className='category-text'>
													Category: {currentFilter ? currentFilter.name : allCategoriesText}
												</span>
												{filterTemplateOptions && filterTemplateOptions?.length > 2 && (
													<>
														{openFilter ? (
															<i className='ri-arrow-up-s-line'></i>
														) : (
															<i className='ri-arrow-down-s-line'></i>
														)}
													</>
												)}
											</div>
											{openFilter && (
												<FilterIngredientTemplateComponent
													onSelectFilter={() => setOpenFilter(false)}
												/>
											)}
										</th>
									</tr>
								</thead>
								<tbody className='custom-table'>
									{tableIngredients?.map((content, index) => {
										return (
											<tr key={index}>
												<td>{content.displayName}</td>
												<td>{content.usages?.map(x => x.name).join(', ')}</td>
												<td>{content.categories?.map(x => x.name)?.join(', ')}</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				) : (
					<div className='list-container'>
						{sortedKeys?.map(usage => (
							<div key={usage} className='item-container'>
								<h3 className='item-title'>{toTitleCase(usage)}</h3>
								<div className='pills-container'>
									{listIngredients &&
										listIngredients[usage].map(displayName => (
											<span key={displayName} className='pill'>
												{displayName}
											</span>
										))}
								</div>
							</div>
						))}
					</div>
				))}
		</>
	);
};
interface INewDetailedTemplateComparisonComponentProps {
	templates: IngredientsTemplate[] | undefined;
	currentTab: number;
	moveToTab: (index: number) => void;
	showDetails: boolean;
	setShowDetails: (showDetailedList: boolean) => void;
	isMobile: boolean;
	isEdit: boolean;
	previewTab: number;
}
export const NewDetailedTemplateComparisonComponent: FC<
	INewDetailedTemplateComparisonComponentProps
> = ({
	templates,
	currentTab,
	moveToTab,
	showDetails,
	setShowDetails,
	isMobile,
	isEdit,
	previewTab
}) => {
	const dispatch = useDispatch();
	const mapIngredientFilterOptions = useCallback(
		(options: IngredientCategory[]) => {
			return dispatch(IngredientFilterActions.setIngredientFilterOptions(options));
		},
		[dispatch]
	);
	const [viewMode, setViewMode] = useState<'list' | 'table'>('table');
	const [tab, setTab] = useState(-1);
	const buttonList: IButtonComponentProps[] = [
		{
			text: `NHN Standards  (${getIngredientsLength(1, templates)})`,
			style: `rounded-button ${tab === 1 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(1);
			}
		},
		{
			text: `Retailer Standards  (${getIngredientsLength(2, templates)})`,
			style: `rounded-button ${tab === 2 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(2);
			}
		},
		{
			text: `Retailer Standards Plus  (${getIngredientsLength(3, templates)})`,
			style: `rounded-button ${tab === 3 ? 'active' : ''} blue`,
			onClick: () => {
				moveToTab(3);
			}
		}
	];
	const [swiperInstance, setSwiperInstance] = useState<ISwiper | null>(null);
	useEffect(() => {
		if (isEdit) {
			setTab(currentTab);
		} else {
			if (previewTab === -1) {
				setTab(currentTab);
				return;
			}
			setTab(previewTab);
		}
	}, [previewTab, currentTab, isEdit]);
	useEffect(() => {
		if (swiperInstance && isMobile && isEdit) {
			swiperInstance.slideTo(currentTab - 1);
		} else if (swiperInstance && isMobile && !isEdit) {
			swiperInstance.slideTo(previewTab - 1);
		}
	}, [currentTab, previewTab, isEdit]);
	const changeViewMode = (viewMode: 'list' | 'table') => {
		setViewMode(viewMode);
	};
	return (
		<div className='detailed-template-profile'>
			<div className='button-detail-container'>
				<div className='spacing-separator'></div>
				<div className='detail-button-profile'>
					<button className='show-btn' type='button' onClick={() => setShowDetails(!showDetails)}>
						{!isMobile &&
							(!showDetails
								? 'Show detailed template comparison'
								: 'Hide detailed template comparison')}
						{isMobile && (!showDetails ? 'Show template comparison' : 'Hide template comparison')}

						{!showDetails ? (
							<svg
								width='12'
								height='8'
								viewBox='0 0 12 8'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M5.99989 4.97665L10.1249 0.851646L11.3032 2.02998L5.99989 7.33331L0.696555 2.02998L1.87489 0.851647L5.99989 4.97665Z'
									fill='#5C666F'
								/>
							</svg>
						) : (
							<svg
								width='12'
								height='8'
								viewBox='0 0 12 8'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M5.99989 3.02332L1.87489 7.14832L0.696555 5.96998L5.99989 0.66665L11.3032 5.96998L10.1249 7.14832L5.99989 3.02332Z'
									fill='#5C666F'
								/>
							</svg>
						)}
					</button>
				</div>
			</div>
			{showDetails && (
				<div id='details'>
					<div className='options-ingredients-container'>
						<div className='button-view-options'>
							<ButtonComponent
								onClick={() => changeViewMode('list')}
								style={`${viewMode === 'list' ? 'active' : ''} square-button`}
								text='List View'
								icon={<i className='ri-list-check'></i>}
								iconPosition='start'
							/>
							<ButtonComponent
								onClick={() => changeViewMode('table')}
								style={`${viewMode === 'table' ? 'active' : ''} square-button`}
								text='Table View'
								icon={<i className='ri-table-line '></i>}
								iconPosition='start'
							/>
						</div>
						<div className='divider'></div>
						<div className='menu-ingredients-container'>
							{!isMobile ? (
								<div className='button-panel-profile'>
									{buttonList.map((btn, index) => {
										return (
											<ButtonComponent
												onClick={btn.onClick}
												text={btn.text}
												style={btn.style}
												icon={btn.icon}
												iconPosition={btn.iconPosition}
												key={index}
											/>
										);
									})}
								</div>
							) : (
								<>
									<div className='detail-slider'>
										<Swiper
											onSwiper={setSwiperInstance}
											slidesPerView={'auto'}
											spaceBetween={16}
											className='swypwer-content-btn'
											pagination={{
												clickable: true
											}}
											modules={[Pagination]}
											onSlideChange={swiper => moveToTab(swiper.activeIndex + 1)}
										>
											{buttonList.map((btn, index) => {
												return (
													<SwiperSlide key={index}>
														<div key={index}>
															<ButtonComponent
																onClick={btn.onClick}
																text={btn.text}
																style={btn.style}
																icon={btn.icon}
																iconPosition={btn.iconPosition}
																id={index === 1 ? 'green' : index === 2 ? 'yellow' : ''}
															/>
														</div>
													</SwiperSlide>
												);
											})}
										</Swiper>
									</div>
								</>
							)}
						</div>
					</div>
					<div className='row'>
						<ViewTemplateComparisonComponent
							viewMode={viewMode}
							templates={templates}
							currentTab={currentTab}
							isMobile={isMobile}
							isEdit={isEdit}
							previewTab={previewTab}
							mapIngredientFilterOptions={mapIngredientFilterOptions}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
