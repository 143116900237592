import { FC, useState, useCallback, useEffect } from 'react';
import { Action, Dispatch } from 'redux';
import { beaconFaqLink } from '../../../common/constants';
import { IngredientsTemplate, ScreenType } from '../../../common/view-model';
import { GET_INGREDIENT_TEMPLATES } from '../../../graphql/queries/ingredientTemplates';
import { getTemplateIngredientsByPurpose } from '../../../helpers/transformers';
import { useWindowSize } from '../../../hooks/useWindowSize';
import { ApolloActions } from '../../../redux/ApolloActions';
import { RootState } from '../../../redux/AppReducer';
import { UserActions } from '../../../redux/UserActions';
import { NewIngredientsTemplateCardComponent } from '../NewIngredientsTemplateCardComponent/NewIngredientsTemplateCardComponent';
import { LoadingIngredientsTemplateComponent } from '../../onbording/LoadingIngredientsTemplate/LoadingIngredientsTemplate';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
import { connect } from 'react-redux';
import './NewProfileIngredientComponent.scss';

export interface IIngredientsTemplateComponentProps {
	ingredientTemplates?: IngredientsTemplate[];
	ingredientsTemplateId?: number;
	setIngredientsTemplateId: (ingredientsTemplateId: number) => void;
	getTemplates: () => void;
	saveIngredientsTemplateId: (id: number) => void;
	screenType: ScreenType | undefined;
	toggleState: string | number;
	getUserProfile: () => void;
	saveTemplateLoding?: boolean;
	setShowDetails: (state: boolean) => void;
	showDetails: boolean;
	currentTabIndex: number;
	detailCurrentTab: number;
	setCurrentTabIndex: (index: number) => void;
	setDetailCurrentTab: (index: number) => void;
	isEditing: boolean;
	setEditing: (state: boolean) => void;
	moveToStepHandler: (index: number) => void;
}
const NewProfileIngredientComponent: FC<IIngredientsTemplateComponentProps> = ({
	ingredientTemplates,
	ingredientsTemplateId,
	setIngredientsTemplateId,
	getTemplates,
	saveIngredientsTemplateId,
	screenType,
	toggleState,
	getUserProfile,
	saveTemplateLoding,
	setShowDetails,
	showDetails,
	detailCurrentTab,
	currentTabIndex,
	setCurrentTabIndex,
	setDetailCurrentTab,
	isEditing,
	setEditing,
	moveToStepHandler
}) => {
	const [templates, setTemplates] = useState<IngredientsTemplate[]>([]);
	const FULL_SIZE_SCREEN_WIDTH = 743;
	const { width } = useWindowSize();
	const save = () => {
		setEditing(false);
		saveIngredientsTemplateId(currentTabIndex);
		setCurrentTabIndex(ingredientsTemplateId!);
		setDetailCurrentTab(ingredientsTemplateId!);
	};
	const handleLearMoreClick = useCallback(() => {
		window.open(beaconFaqLink, '_blank', 'rel=noopener noreferrer');
	}, []);

	useEffect(() => {
		if (ingredientTemplates) {
			ingredientTemplates = ingredientTemplates.map(template => ({
				...template,
				purpose: getTemplateIngredientsByPurpose(template)
			}));
			setTemplates(ingredientTemplates!);
		} else {
			getTemplates();
		}
	}, [getTemplates, ingredientTemplates]);
	useEffect(() => {
		setEditing(false);
	}, [toggleState]);
	useEffect(() => {
		if (!ingredientsTemplateId) {
			getUserProfile();
		} else {
			if (!isEditing) {
				setCurrentTabIndex(ingredientsTemplateId);
			}
			setDetailCurrentTab(ingredientsTemplateId);
		}
	}, [ingredientsTemplateId]);

	return (
		<>
			{!saveTemplateLoding && ingredientsTemplateId && templates && templates.length > 0 ? (
				<div className='new-ingredients-template'>
					<div className='template-header'>
						<div className='template-title-show'>
							{!(screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH)
								? 'You selected Ingredient Template:'
								: 'You selected Template:'}{' '}
							<span className='card-name'>{templates[currentTabIndex - 1]?.name}</span>{' '}
						</div>
						{/* <div className='template-profile-learn-more-container'>
							<ActionLinkComponent
								text='Learn More'
								underline={true}
								onClick={handleLearMoreClick}
								icon='ri-arrow-right-line'
								iconPosition='end'
								iconOnly={false}
								color='dark'
								fontWigth='normal'
								size={
									screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH
										? 'small'
										: 'big'
								}
								to={beaconFaqLink}
								linkToExternal={true}
							/>
						</div> */}
					</div>
					<div className='ingredients-options-container'>
						<h3 className='ingredients-options-title'>Template Options</h3>
						<div className='ingredients-edit'>
							<div
								className={`edit-buttons ${isEditing ? 'save' : 'edit'}`}
								onClick={() => {
									if (isEditing) {
										save();
									}
									setEditing(!isEditing);
								}}
							>
								{!isEditing && (
									<>
										<i className='ri-edit-line' />
										<p>Edit Details</p>
									</>
								)}
								{isEditing && (
									<>
										<i className='ri-save-line' />
										<p className='save-button'>Save Changes</p>
									</>
								)}
							</div>
						</div>
					</div>
					<div className='col-ingredients-profile'>
						<div className='row-ingredients-profile'>
							<div
								className={`new-template-cards-profile  ${
									currentTabIndex === 1
										? 'ing-current-blue'
										: currentTabIndex === 2
										? 'ing-current-green'
										: 'ing-current-yellow'
								}`}
							>
								<NewIngredientsTemplateCardComponent
									currentTab={currentTabIndex}
									templates={templates}
									moveToTab={moveToStepHandler}
									setShowDetailedList={setShowDetails}
									showDetailedList={showDetails}
									isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
									isEdit={isEditing}
								/>
							</div>
						</div>
					</div>
					{isEditing && (
						<div className='actions'>
							<ButtonComponent
								onClick={() => setEditing(false)}
								style='rounded-button secondary'
								text='Undo Changes'
							/>
							<ButtonComponent
								onClick={() => {
									save();
									setEditing(false);
								}}
								style='rounded-button primary'
								text='Save Changes For This Section'
							/>
						</div>
					)}
				</div>
			) : (
				<LoadingIngredientsTemplateComponent
					loadingBottomPillsCount={3}
					loadingCardsCount={3}
					loadingTopPillsCount={
						screenType === ScreenType.TABLET ||
						screenType === ScreenType.MOBILE ||
						width === FULL_SIZE_SCREEN_WIDTH
							? 5
							: 4
					}
					isMobile={screenType === ScreenType.MOBILE || width === FULL_SIZE_SCREEN_WIDTH}
					profilePage={true}
				/>
			)}
		</>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({
	ingredientTemplates: state.storeState?.templates,
	ingredientsTemplateId: state.userState?.ingredientsTemplateId,
	screenType: state.deviceScreen,
	saveTemplateLoding: state.userState?.saveTemplateLoding
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setIngredientsTemplateId: (ingredientsTemplateId: number) =>
		dispatch(UserActions.setIngredientsTemplateId(ingredientsTemplateId)),
	getTemplates: () => {
		return dispatch(ApolloActions.query({ query: GET_INGREDIENT_TEMPLATES }));
	},
	saveIngredientsTemplateId: (id: number) =>
		dispatch({ type: UserActions.SAVE_USER_INGREDIENTS_TEMPLATE_ID, payload: id }),
	getUserProfile: () => dispatch({ type: UserActions.GET_USER_PROFILE })
});

export default connect(mapStateToProps, mapDispatchToProps)(NewProfileIngredientComponent);
