import { useState } from 'react';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import { SecondaryLabelForm } from '../../GenericBaseForms/SecondaryLabelForm/SecondaryLabelForm';
import React from 'react';
import './ProductViolationsFormComponent.scss';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { ProductInfo } from '../../../../redux/AppReducer';

interface IProductViolationsForm {
	productState: ProductInfo;
}
interface IViolations {
	change: string;
	description: string | React.ReactElement;
	action: string;
	id: string;
}

const ProductViolationsFormComponent: React.FC<IProductViolationsForm> = ({ productState }) => {
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	const { width } = useWindowSize();
	const FULL_SIZE_SCREEN_WIDTH = 768;
	const isMobile = width < FULL_SIZE_SCREEN_WIDTH;
	const ViolationsArray = [
		{
			change: 'Allergen Warning Missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product contains an ingredient that requires an allergen declaration which	is not present. Allergen declaration is required.',
			id: 'allergenMissing'
		},
		{
			change: 'Claims or TM logo requires certification',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product label indicates the product is certified organic, certified gluten free and/or non-GMO verified/certified. The supporting certificatedocumentation is required or the label may require appropriate revisions.',
			id: 'claimsLogoRequiresCertification'
		},
		{
			change: 'Botanic plant parts missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Dietary supplement products containing botanical substances are required to include the plant parts used within the ingredient declaration.',
			id: 'botanicPartsMissing'
		},
		{
			change: 'Directions for use',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Products that could be unsafe if used improperly must identify directions for safe use and warnings against misuse. Appropriate directions for use are	missing from the label.',
			id: 'directionsForUse'
		},
		{
			change: 'Facts panel missing or incorrect',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description: '',
			id: 'factPanelMissing'
		},
		{
			change: 'Ingredients list missing or incorrect',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Ingredients list includes items that must be broken down into its sub-ingredient components; ingredients declaration is incorrect. OR product contains more than one ingredient making an ingredients list required, and it is missing',
			id: 'ingredientsListMissing'
		},
		{
			change: 'Manufacturer address missing or inadequate',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'For the address to be complete, a city, state and zip code are required. If any, or all of these components are missing and/or only a web address is present, the required address is missing or incomplete.',
			id: 'manufacturerAddressMissing'
		},
		{
			change: 'Misuse of an FDA approved health claim',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'The product may meet the requirements to use an U.S. FDA approved health claim, but the language of the claim does not align with the language	approved for use by the U.S. FDA.',
			id: 'misuseFDAApprovedHealthClaim'
		},
		{
			change: 'Net quality of contents missing or inadequate',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Net quantity of contents must be present in both avoirdupois measure (g., ounce, pound, fluid ounce, pint, quart) and metric measure (e.g., milligram, gram, milliliter, liter). One of these measurements is missing on the label.',
			id: 'netQualityContentsMissing'
		},
		{
			change: 'Natural claims',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product label includes organic claims that require the product be certified organic with the certifying agent identified on the information panel. The certifying agent does not appear on this product label and it is required. If the product is not certified organic, the organic claim(s) need to be removed.',
			id: 'naturalClaims'
		},
		{
			change: 'Statement of identity missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'The nutrient amount declared on the PDP does not match the amount per serving in the Facts panel. It is unclear if this amount is per container or	per serving or some other number of servings. Clarification on the label is	required.',
			id: 'statementIdentityMissing'
		},
		{
			change: 'Disease claims',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description:
				'Product includes a claim that establishes the intended use of the product as a drug. It has also been determined that the product does not qualify for an FDA approved health claim. New Hope Network Standards do not permit products	to make disease or drug-like clams.',
			id: 'diseaseClaims'
		},
		{
			change: 'FDA logo misuse',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description: (
				<>
					Product includes the FDA logo that is not for use on branded products per FDA logo policy.
					Please see details here:{' '}
					<a
						href='https://www.fda.gov/about-fda/website-policies/fda-name-and-logo-policy'
						target='_blank'
						rel='noreferrer'
					>
						{' '}
						https://www.fda.gov/about-fda/website-policies/fda-name-and-logo-policy
					</a>
					.
				</>
			),
			id: 'FDALogoMisuse'
		},
		{
			change: 'Nutrient Content Claims',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description: 'Product contains these ingredients which are prohibited.',
			id: 'nutrientContentClaims'
		},
		{
			change: 'Ingredient standards ',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.  ',
			description: (
				<>
					Product contains at least one ingredient that is prohibited by New Hope Network
					Ingredients Standards & Guidelines. See{' '}
					<a
						href='https://standards.newhope.com/ingredient-standards-guidelines/'
						target='_blank'
						rel='noreferrer'
					>
						{' '}
						https://standards.newhope.com/ingredient-standards-guidelines/
					</a>
					&nbsp; for details.
				</>
			),
			id: 'ingredientStandards'
		},
		{
			change: 'Label not to US requirements',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.',
			description: 'Product label is not formatted to all U.S. FDA labeling requirements.',
			id: 'labelNotUSRequirements'
		},
		{
			change: 'Organic certifying agent missing',
			action:
				'Please do this that and the other to ensure your product is compliant with regulations.',
			description:
				'Product label includes organic claims that require the product be Certified Organic with the certifying agent identified on the information panel. The certyfiing agent does not appear on this product label and it is required. If the product is not Certified Organic, the organic claim(s) need to be removed.',
			id: 'organicCertifyingAgentMissing'
		}
	];
	const violations: IViolations[] = ViolationsArray.filter(
		violation => productState.violations[violation.id]
	);
	if (violations.length > 0) {
		return (
			<div className='product-violations-form'>
				<TitleForms
					isVisible={isVisible}
					titleText='Violations'
					toggleVisibility={toggleVisibility}
					readOnly
					warning
				></TitleForms>
				{!isMobile && isVisible ? (
					<>
						<p className='helper-text'>
							To provide additional information, please contact your Standards Specialist at
							<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
								{' '}
								standards@newhope.com.
							</a>{' '}
						</p>
						<table>
							<thead>
								<tr>
									<th>
										<SecondaryLabelForm text='Change'></SecondaryLabelForm>
									</th>
									<th>
										<SecondaryLabelForm text='Description'></SecondaryLabelForm>
									</th>
									<th>
										<SecondaryLabelForm text='Action'></SecondaryLabelForm>
									</th>
								</tr>
							</thead>
							<tbody>
								{violations.map((violation, index) => (
									<tr key={index + productState.id}>
										<td>{violation.change}</td>
										<td>{violation.description}</td>
										<td>{violation.action}</td>
									</tr>
								))}
							</tbody>
						</table>
					</>
				) : (
					<div className='violations-container'>
						<p className='helper-text'>
							To provide additional information, please contact your Standards Specialist at
							<a href='mailto:Standards@newhope.com' target='_blank' rel='noreferrer'>
								{' '}
								standards@newhope.com.
							</a>{' '}
						</p>
						{violations.map((violations, index) => (
							<div key={index + productState.id} className='violation-container'>
								<div className='violation-element'>
									<SecondaryLabelForm text='Change'></SecondaryLabelForm>
									<p> {violations.change}</p>
								</div>

								<div className='violation-element'>
									<SecondaryLabelForm text='Description'></SecondaryLabelForm>
									<p> {violations.description}</p>
								</div>

								<div className='violation-element'>
									<SecondaryLabelForm text='Action'></SecondaryLabelForm>
									<p> {violations.action}</p>
								</div>
								<div className='separator'></div>
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
	return null;
};

export default React.memo(ProductViolationsFormComponent);
