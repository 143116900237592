import { FC } from 'react';
import { ButtonComponent } from '../UI/ButtonComponent/ButtonComponent';
import './WarningFormOverlayComponent.scss';
interface WarningOverLayComponentProps {
	closeOverlay: () => void;
	updateData: (userData: any) => void;
	cancelUpdate: () => void;
	formData: any;
}
export const WarningFormOverlayComponent: FC<WarningOverLayComponentProps> = ({
	cancelUpdate,
	closeOverlay,
	formData,
	updateData
}) => {
	return (
		<div className='warning-overlay-container'>
			<div className='warning-title-container'>
				<p>Save your changes</p>
				<ButtonComponent
					onClick={closeOverlay}
					style='rounded-button tertiary menu-button'
					text=''
					icon={<i className='ri-close-line'></i>}
					iconOnly
				/>
			</div>
			<div className='warning-body-container'>
				<div className='warning-description-container'>
					<p className='description-title'>You are currently editing another section.</p>
					<p className='description-body'>
						Do you want to save your changes before editing the next section?
					</p>
				</div>
				<div className='warning-buttons-container'>
					<ButtonComponent
						style='rounded-button secondary'
						text='Undo Changes'
						onClick={() => {
							cancelUpdate();
							closeOverlay();
						}}
					/>
					<ButtonComponent
						style='rounded-button primary'
						text='Save Changes For This Section'
						onClick={() => {
							updateData(formData);
							closeOverlay();
						}}
					/>
				</div>
			</div>
		</div>
	);
};
