import { gql } from 'graphql.macro';
export const UPDATE_PROFILE = gql`
	mutation UpdateProfile(
		$id: ID!
		$ingredientsTemplateId: String
		$email: String
		$companyDetails: CompanyDetailsInput
	) {
		updateProfile(
			updateProfile: {
				id: $id
				email: $email
				ingredientsTemplateId: $ingredientsTemplateId
				companyDetails: $companyDetails
			}
		) {
			email
			ingredientsTemplateId
			favoriteProducts {
				pid
				addDate
			}
			companyDetails {
				userType
				jobType
				website
				name
			}
		}
	}
`;

export const ADD_FAVORITE = gql`
	mutation AddFavorite($id: String!, $productId: String!) {
		addFavoriteProduct(profileId: $id, productId: $productId) {
			favoriteProducts {
				pid
				addDate
			}
		}
	}
`;

export const DELETE_FAVORITE = gql`
	mutation DeleteFavorite($id: String!, $productId: String!) {
		deleteFavoriteProduct(profileId: $id, productId: $productId) {
			favoriteProducts {
				pid
				addDate
			}
		}
	}
`;

export const CREATE_PROFILE = gql`
	mutation CreateProfile($id: ID!, $email: String!, $ingredientsTemplateId: String!) {
		createProfile(
			createProfileInput: { id: $id, email: $email, ingredientsTemplateId: $ingredientsTemplateId }
		) {
			id
			email
			ingredientsTemplateId
		}
	}
`;
