const DEFAULT_STATE: IEngagementDashboardState = {
	beaconDiscoveryTitle: 'Beacon Discovery',
	engagementTitle: 'Engagement Dashboard',
	engagementSubtitle: 'Engagement With Your Brand And Beacon User Metrics',
	brandProfileButtonText: 'See Your Brand Profile Page',
	whoIsOnBeaconTitle: 'Who’s on Beacon Discovery?'
};
interface IEngagementDashboardState {
	beaconDiscoveryTitle: string;
	engagementTitle: string;
	engagementSubtitle: string;
	brandProfileButtonText: string;
	whoIsOnBeaconTitle: string;
}
export const engagementDashboardModel = {
	...DEFAULT_STATE
};
