import { FC, useEffect, useState } from 'react';
import { FavoriteProductListComponent } from '../../components/Favorites/favorite-product-list/ProductListComponent';
import { connect, useSelector } from 'react-redux';
import { ErrorState, FavoriteAddDate, RootState } from './../../redux/AppReducer';
import { ApolloActions } from './../../redux/ApolloActions';
import { Action, Dispatch } from 'redux';
import { GET_PRODUCTS } from '../../graphql/queries/products';
import { AppActions } from '../../redux/AppActions';
import { FavoriteHeaderComponent } from '../../components/Favorites/FavoriteHeader/FavoriteHeaderComponent';
import { sortOptionType } from '../../common/view-model';
import { UserType, Product } from '../../common/model';
import { useQuery } from '@apollo/client';
import { findUserAccessType } from '../../helpers/findUserAccessType';
import { serverError } from '../../common/constants';

const FavoritesPage: FC<RootState> = (props: any) => {
	const { isProfilePage, currentEvent } = props;
	const [viewMode] = useState('grid');
	const [userType, setUserType] = useState<UserType | undefined>();
	const [favoriteProducts, setFavoriteProducts] = useState<Array<FavoriteAddDate> | undefined>();
	const [user, setUser] = useState<any>();
	const [ingredientsTemplateId, setIngredientsTemplateId] = useState<number | undefined>();
	const userState = useSelector((state: RootState) => state.appState?.userState!);
	const { favoriteSortBySelected } = useSelector(
		(state: RootState) => state.appState?.filterState!
	);
	const [products, setProducts] = useState<Product[]>([]);

	useEffect(() => {
		setFavoriteProducts(userState.favoriteProducts);
		setIngredientsTemplateId(userState.ingredientsTemplateId);
		setUser(userState.user);
		setUserType(userState.user?.user_type);
	}, [userState]);

	useEffect(() => {
		props.setStandAloneLayout(false);
	}, []);
	const { loading, data, error, refetch } = useQuery(GET_PRODUCTS, {
		variables: {
			genericFilters: [],
			sortAttribute: favoriteSortBySelected?.productSort.attribute,
			sortDirection: favoriteSortBySelected?.productSort.order,
			templateId: String(ingredientsTemplateId || '1'),
			favoriteProducts: true,
			expoEventId: currentEvent?.id || null
		},
		fetchPolicy: 'no-cache'
	});
	useEffect(() => {
		if (!error && !loading && data?.products?.products && currentEvent !== undefined) {
			setProducts(data?.products?.products);
		}
		if (error?.message === 'Unauthorized') {
			props.handleUnauthorized();
		}
		if (error) {
			props.handleError(serverError);
		}
	}, [loading, data, error, currentEvent]);
	useEffect(() => {
		if (
			!userType ||
			(findUserAccessType(userType) === UserType.Retailer && !ingredientsTemplateId) ||
			!user ||
			!favoriteProducts ||
			currentEvent === undefined
		) {
			return;
		}
		refetch();
	}, [
		refetch,
		ingredientsTemplateId,
		user,
		favoriteProducts,
		favoriteSortBySelected,
		userType,
		currentEvent
	]);
	return (
		<>
			<FavoriteHeaderComponent products={products} isLoading={loading && currentEvent?.id} />
			<FavoriteProductListComponent
				products={products}
				viewMode={viewMode}
				isProfilePage={isProfilePage}
				isLoading={loading && currentEvent?.id}
			/>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	...state,
	favoriteProductsArr: state.appState.userState?.favoriteProducts || [],
	currentEvent: state.appState.storeState?.getCurrentEvent
});

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	getProducts: (variables = null) => {
		return dispatch(ApolloActions.query({ query: GET_PRODUCTS, variables }));
	},
	setStandAloneLayout: (standAloneLayout: boolean) =>
		dispatch(AppActions.setStandAloneLayout(standAloneLayout)),
	handleUnauthorized: () => {
		return dispatch(AppActions.unauthorizedError());
	},
	handleError: (error: ErrorState) => {
		return dispatch(AppActions.setGeneralError(error));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FavoritesPage);
