import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { combineReducers, applyMiddleware, legacy_createStore } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { Action } from 'redux-actions';
import { appReducer, AppState, initialRootState, RootState } from './redux/AppReducer';
import { AppEpics } from './redux/AppEpics';
import { apolloClient } from './graphql/ApolloClient';
import { ApolloEpics } from './redux/ApolloEpics';
import { AuthEpics } from './redux/AuthEpics';
import { FilterEpics } from './redux/FilterEpics';
import { ApolloProvider } from '@apollo/client';
import { composeWithDevTools } from 'redux-devtools-extension';
import { UserEpics } from './redux/UserEpics';
import 'remixicon/fonts/remixicon.css';
import { ScreenEpics } from './redux/ScreenEpics';
import { ToastEpics } from './redux/toast/ToastEpics';
import { configureNewRelic } from './newrelic/newrelic';
import { SearchEpics } from './redux/search/SearchEpics';
import { EngagementDashboardEpics } from './redux/engagementDashboard/EngagementDashboardEpics';

export const epics = combineEpics(
	...AuthEpics.init(),
	...AppEpics.init(),
	...ApolloEpics.init(),
	...FilterEpics.init(),
	...UserEpics.init(),
	...ScreenEpics.init(),
	...ToastEpics.init(),
	...SearchEpics.init(),
	...EngagementDashboardEpics.init()
);

export const reducers = combineReducers({
	appState: appReducer
});

const epicMiddleware = createEpicMiddleware<Action<any>, Action<any>, AppState>();

export default function configureStore(initialState?: RootState) {
	const composedEnhancer = composeWithDevTools(applyMiddleware(epicMiddleware));
	const store = legacy_createStore(reducers, initialState, composedEnhancer);
	epicMiddleware.run(epics as any);
	return store;
}

const store = configureStore(initialRootState);

//activating new relic metrics
if (process.env.REACT_APP_NR_ACTIVATE_METRICS === 'true') {
	const configNewRelicByEnvironment = document.createElement('script');
	configNewRelicByEnvironment.text = configureNewRelic();
	configNewRelicByEnvironment.async = true;
	document.head.insertBefore(configNewRelicByEnvironment, document.head.firstElementChild);

	const configNewRelic = document.createElement('script');
	configNewRelic.src = '/scripts/newrelic.js';
	configNewRelic.async = true;
	document.head.insertBefore(configNewRelic, document.head.firstElementChild);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<ApolloProvider client={apolloClient}>
			<App />
		</ApolloProvider>
	</Provider>
);
reportWebVitals();
