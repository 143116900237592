const DEFAULT_STATE: IEngagementTableModel = {
	showLessText: 'Show Less',
	showMoreText: 'Show More'
};

interface IEngagementTableModel {
	showMoreText: string;
	showLessText: string;
}
export const EngagementTableModel = {
	...DEFAULT_STATE
};
