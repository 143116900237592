import { Dispatch, FC, useEffect, useState } from 'react';
import { Action } from 'redux';
import { connect } from 'react-redux';
import './index.scss';
import { BrandOnboardTitle } from '../../components/BrandOnboard/BrandOnboardTitle/BrandOnboardTitle';
import { BrandOnboardContent } from '../../components/BrandOnboard/BrandOnboardContent/BrandOnboardContent';
import { BrandOnboardFooter } from '../../components/BrandOnboard/BrandOnboardFooter/BrandOnboardFooter';
import { isCurrentUserAnonymous } from '../../helpers/authenticationHelper';
import { eventPublicPathName } from '../../common/constants';
import { IBrandOnboardContent } from '../../common/view-model';
import { RootState } from '../../redux/AppReducer';
import { FirstTimeExpoComponent } from '../../components/BrandOnboard/FirstTimeExpo/FirstTimeExpoComponent';
import { NeedToMakeChangesComponent } from '../../components/BrandOnboard/NeedToMakeChanges/NeedToMakeChangesComponent';
import { BeaconOnlyBrandComponent } from '../../components/BrandOnboard/BeaconOnlyBrand/BeaconOnlyBrandComponent';
import { BrandOnboardPageModel as model } from './model';
import { AppActions } from '../../redux/AppActions';

interface BrandOnboardPageComponentProps {
	toggleState?: string | number;
	setIsStandAloneLayout: (isStandAlone: boolean) => void;
}
const BrandOnboardPageComponent: FC<BrandOnboardPageComponentProps> = ({
	toggleState,
	setIsStandAloneLayout
}) => {
	const brandContentElements: IBrandOnboardContent[] = [
		{
			...model.firstTimeComponentProps,
			content: <FirstTimeExpoComponent />
		},
		{
			...model.needChangesComponentProps,
			content: <NeedToMakeChangesComponent />
		},
		{
			...model.beaconOnlyComponentProps,
			content: <BeaconOnlyBrandComponent />
		}
	];
	const featureProductId = '64d192f6ae6f5265ed44dddf';
	const featureBrandId = '1687307593_649245490140b6.73924905';
	const [contentElements, setContentElements] =
		useState<IBrandOnboardContent[]>(brandContentElements);
	const handleSetActive = (id: string) => {
		const validateShouldCloseAll = contentElements.find(
			element => element.id === id && element.active
		);
		const newElements = contentElements.map(element => {
			element.active = element.id === id && !validateShouldCloseAll ? !element.active : false;
			return element;
		});
		setContentElements(newElements);
	};
	useEffect(() => {
		setContentElements(
			brandContentElements.map(element => ({
				...element,
				active: false
			}))
		);
	}, [toggleState]);
	useEffect(() => {
		if (isCurrentUserAnonymous()) {
			setIsStandAloneLayout(true);
		}
		return () => setIsStandAloneLayout(false);
	}, [setIsStandAloneLayout]);

	return (
		<section
			className={`brand-onboard-page-container ${
				isCurrentUserAnonymous() ? 'public-brand-onboard-page' : ''
			}`}
		>
			<div className='title-container'>
				<BrandOnboardTitle
					featuredProductLink={`${window.location.origin}${
						isCurrentUserAnonymous() ? `/${eventPublicPathName}` : ''
					}/detail/${featureProductId}`}
					featuredBrandLink={`${window.location.origin}${
						isCurrentUserAnonymous() ? `/${eventPublicPathName}` : ''
					}/brand/${featureBrandId}`}
				/>
			</div>
			<div className='content-container'>
				<BrandOnboardContent elements={contentElements} handelSetActive={handleSetActive} />
			</div>
			<div className='footer-container'>
				<BrandOnboardFooter />
			</div>
		</section>
	);
};
const mapStateToProps = ({ appState: state }: RootState) => ({ ...state });

const mapDispatchToProps = (dispatch: Dispatch<Action<any>>, props: any) => ({
	setIsStandAloneLayout: (isProfileLayout: boolean) => {
		dispatch(AppActions.setProfileLayout(isProfileLayout));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandOnboardPageComponent);
