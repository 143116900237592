import { FC, useId, useRef } from 'react';
import './BrandOnboardContent.scss';
import { BrandContentModel as model } from './model';
import { IBrandOnboardContent } from '../../../common/view-model';
import { ButtonComponent } from '../../UI/ButtonComponent/ButtonComponent';
interface IAccordionItemContent {
	id: string;
	title: string;
	handleSetActive: (id: string) => void;
	active: boolean;
	content: JSX.Element;
}
const AccordionItemContent: FC<IAccordionItemContent> = ({
	id,
	title,
	handleSetActive,
	active,
	content
}) => {
	const accordionClickableId = useId();
	const handleClick = (event: any) => {
		event.stopPropagation();
		if (active && event.target.id !== accordionClickableId) return;
		handleSetActive(id);
	};
	return (
		<div
			id={id}
			className={`accordion-item-container ${!active ? 'clickable' : ''}`}
			onClick={handleClick}
		>
			<div className='accordion-title-container'>
				<span
					id={accordionClickableId}
					className={`accordion-title ${active ? 'active' : ''}`}
					onClick={handleClick}
				>
					{title}
				</span>
				<i
					id={accordionClickableId}
					className={`${
						active ? 'main-menu-close ri-subtract-line' : 'main-menu-open ri-add-line'
					}`}
					onClick={handleClick}
				></i>
			</div>
			{active && <div className='accordion-content'>{content}</div>}
			<div className='separator'></div>
		</div>
	);
};

interface IOnboardContentCard {
	id: string;
	title: string;
	description: string;
	handleSetActive: (id: string) => void;
	activeElementId?: string;
	accordionContainerRef?: React.RefObject<HTMLDivElement>;
}
const OnboardContentCard: FC<IOnboardContentCard> = ({
	id,
	title,
	description,
	handleSetActive,
	activeElementId,
	accordionContainerRef
}) => {
	const handleCardClick = () => {
		if (activeElementId !== id) {
			handleSetActive(id);
		}
		accordionContainerRef?.current?.scrollIntoView({ behavior: 'smooth' });
	};
	return (
		<div className='onboard-card-container'>
			<div className={`image-container ${id}`}></div>
			<div className='information-container'>
				<div className='name-container'>
					<span className='name'>{title}</span>
				</div>
				<div className='description-container'>
					<span className='description'>{description}</span>
				</div>
				<div className='button-container'>
					<ButtonComponent
						style='rounded-button secondary'
						onClick={handleCardClick}
						text={model.btnText}
						icon={<i className='ri-arrow-right-s-line line-icon'></i>}
					/>
				</div>
			</div>
		</div>
	);
};

interface IBrandOnboardContentProps {
	elements: IBrandOnboardContent[];
	handelSetActive: (id: string) => void;
}
export const BrandOnboardContent: FC<IBrandOnboardContentProps> = ({
	elements,
	handelSetActive
}) => {
	const accordionContainerRef = useRef<HTMLDivElement>(null);
	return (
		<div className='brand-onboard-content-container'>
			<div className='brand-onboard-title-container'>
				<span className='title'>{model.title}</span>
			</div>
			<div className='cards-container'>
				{elements.map(element => (
					<OnboardContentCard
						key={element.id}
						id={element.id}
						title={element.title}
						description={element.description}
						handleSetActive={handelSetActive}
						activeElementId={elements.find(element => element.active)?.id}
						accordionContainerRef={accordionContainerRef}
					/>
				))}
			</div>
			<div ref={accordionContainerRef} className='onboard-accordion-container'>
				{elements.map(element => (
					<AccordionItemContent
						key={element.id}
						id={element.id}
						title={element.title}
						active={element.active}
						handleSetActive={handelSetActive}
						content={element.content}
					/>
				))}
			</div>
		</div>
	);
};
