import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import './ProductCertificationsFormComponent.scss';
import { Product } from '../../../../common/model';
import { ProductInfo } from '../../../../redux/AppReducer';
interface IProductCertificationsForm {
	productState: ProductInfo;
}
const ProductCertificationsFormComponent: React.FC<IProductCertificationsForm> = ({
	productState
}) => {
	const [isVisible, setVisibility] = useState<boolean>(true);
	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	return (
		<div className='product-certifications-form'>
			<TitleForms
				isVisible={isVisible}
				titleText='Certifications'
				toggleVisibility={toggleVisibility}
				readOnly></TitleForms>
			{isVisible && (
				<>
					<p className='helper-text'>
						To provide updated certification information, email
						<a href='mailto:Standards@newhope.com.<'> standards@newhope.com.</a>{' '}
					</p>
					<ContainerForms
						FormComponent1={
							<>
								{' '}
								<div>
									<LabelForms
										text='Certified Gluten Free
											'></LabelForms>
									<p>{productState.certifiedGlutenFree ? 'Verified' : 'Not Verified'}</p>
								</div>
								<div>
									<LabelForms
										text='Certified Organic
											'></LabelForms>
									<p>{productState.certifiedOrganic ? 'Verified' : 'Not Verified'}</p>
								</div>
							</>
						}
						FormComponent2={
							<>
								{' '}
								<div>
									<LabelForms
										text='Certified Non-GMO
											'></LabelForms>
									<p>{productState.certifiedNonGMO ? 'Verified' : 'Not Verified'}</p>
								</div>
							</>
						}></ContainerForms>
				</>
			)}
		</div>
	);
};

export default React.memo(ProductCertificationsFormComponent);
