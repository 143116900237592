import { OnboardHeaderComponent } from '../OnboardHeader/OnboardHeaderComponent';
import './NeedToMakeChangesComponent.scss';
import { NeedToMakeChangesModel as model } from './model';
import { TimeLineComponent, TimeLineStepComponent } from '../TimeLine/TimeLineComponent';
import { NeedChangesDesktopSteps as steps } from './TimeLineConfigDesktop';
const HeaderContent = () => {
	return (
		<div className='make-changes-header-text-container'>
			<span className='make-changes-header-text'>{model.headerPrevText}</span>{' '}
			<a href={`mailto:${model.headerEmailLink}`} target='_blank' rel='noopener noreferrer'>
				{model.headerEmailLink}
			</a>{' '}
			<span className='make-changes-header-text'>{model.headerNextText}</span>
		</div>
	);
};

export const NeedToMakeChangesComponent = () => {
	return (
		<div className='make-changes-container'>
			<OnboardHeaderComponent title={model.title} children={<HeaderContent />} />
			<div className='need-changes-timeline-content-container'>
				<TimeLineComponent classes='need-changes'>
					{steps.map((step, index) => (
						<div className='timeline-step' key={index}>
							<TimeLineStepComponent {...step} />
						</div>
					))}
				</TimeLineComponent>
			</div>
		</div>
	);
};
