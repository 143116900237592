import { newHopeAudienceEmail } from '../../../common/constants';

const DEFAULT_STATE: IFirsTimeExpoState = {
	title: 'Get the Support You Need for Onboarding!',
	headerPrevText:
		'Allow us to upload your product data for you by giving consent in the Standards Form in Step 1 or emailing',
	headerNextText:
		'with "I’d like assistance with uploading my product data" after booking your booth.',
	headerEmailLink: newHopeAudienceEmail,
	firstCardTitle:
		'NEW – This is my first time exhibiting at an Expo or my first time since May 2023',
	firstCardPrevText: 'Complete the New Exhibitor Standards form',
	firstCardPostText: '& once approved, book your booth',
	firstCardLinkText: 'here',
	firstCardLink: 'https://standards.newhope.com/new-exhibitor-application/',
	firstCardContentText:
		'Check ‘Yes’ on the consent page to get product uploading support for your onboarding. ',
	secondCardTitle:
		'RETURNING – I exhibited at Expo East 2023, Expo West 2024 or Newtopia Now 2024 but don’t have my products on Beacon yet ',
	secondCardPrevText: 'Book your booth then',
	secondCardPostText: 'us so we can update your existing application.',
	secondLinkText: 'email',
	secondCardLink: 'https://survey.alchemer.com/s3/7144903/e792d581d6a7',
	secondCardEmail: newHopeAudienceEmail,
	secondCardContentText: '',
	thirdCardPrevText:
		'Create an account for New Hope Network’s Product Portal offered by Pinto/SPINS',
	thirdCardLinkText: 'here',
	thirdCardContentText:
		'Once your account is created, you will receive your login credentials via email.',
	thirdCardLink: 'https://www.spins.com/pi-manager/signup-request/?partnerName=new-hope-network ',

	fourthCardPrevText: 'Upload product data and images into Product Portal for review',
	fourthCardLinkText: 'here',
	fourthCardLink: 'https://survey.alchemer.com/s3/7555625/NHN-Consents-to-Onboard-with-SPINS-Pinto',
	fourthCardSecondLink:
		'https://standards.newhope.com/wp-content/uploads/2024/09/NHN_Guide_Updated_SEPT_2024-4.pdf',
	fourthCardContentBoldText: 'If you would like to upload yourself,',
	fourthCardContentBoldAfterText: 'are the instructions.',
	fourthCardContentProductsUploadText: 'Let us upload your product data for you!  ',
	fourthCardContentPrev: 'Give consent in the Standards form',
	fourthCardContentFormAfterText: 'then email',
	fourthCardEmail: newHopeAudienceEmail,
	fourthCardContentPostText: 'and say “I want New Hope to upload product data for me.”',
	fiveCardHeaderText: 'See your products on Beacon Discovery',
	fiveCardContentText:
		'Once approved by our Standards team, your brand can be discovered by retailers on Beacon immediately!'
};

interface IFirsTimeExpoState {
	title: string;
	headerPrevText: string;
	headerNextText: string;
	headerEmailLink: string;
	firstCardTitle: string;
	firstCardPrevText: string;
	firstCardPostText: string;
	firstCardLinkText: string;
	firstCardLink: string;
	firstCardContentText: string;

	secondCardTitle: string;
	secondCardPrevText: string;
	secondCardPostText: string;
	secondLinkText: string;
	secondCardLink: string;
	secondCardEmail: string;
	secondCardContentText: string;

	thirdCardPrevText: string;
	thirdCardLinkText: string;
	thirdCardLink: string;
	thirdCardContentText: string;

	fourthCardPrevText: string;
	fourthCardLinkText: string;
	fourthCardLink: string;
	fourthCardSecondLink: string;
	fourthCardContentPrev: string;
	fourthCardContentPostText: string;
	fourthCardContentBoldText: string;
	fourthCardContentBoldAfterText: string;
	fourthCardContentProductsUploadText: string;
	fourthCardContentFormAfterText: string;
	fourthCardEmail: string;

	fiveCardHeaderText: string;
	fiveCardContentText: string;
}
export const FirstTimeExpoModel = {
	...DEFAULT_STATE
};
