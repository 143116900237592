import { StateObservable, ofType } from 'redux-observable';
import { Observable, catchError, mergeMap, of } from 'rxjs';
import { SearchActions } from './SearchActions';
import { getIirisCookie } from '../../helpers/authenticationHelper';
import { ajax } from 'rxjs/ajax';
import { ISortByOption, sortOptionType } from '../../common/view-model';
import { RootState } from '../AppReducer';
import { v4 as uuidv4 } from 'uuid';

export class SearchEpics {
	static init() {
		return [SearchEpics.starSuggestions, SearchEpics.setSearchTerm];
	}
	static starSuggestions(action$: Observable<any>): Observable<any> {
		return action$.pipe(
			ofType(SearchActions.START_SEGGESTION_SEARCH),
			mergeMap(_action => {
				const request = {
					url: `${process.env.REACT_APP_BEACON_API_URL}/search`,
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: getIirisCookie()
					},
					body: {
						term: _action.payload
					}
				};
				return ajax(request).pipe(
					mergeMap((result): any => {
						const { response }: any = result;
						const { suggestions } = response;
						return of(SearchActions.searchSuggestionsSuccess(suggestions));
					})
				);
			}),
			catchError(() => of(SearchActions.SEARCH_SUGGESTIONS_FAILURE))
		);
	}
	static readonly setSearchTerm = (
		action$: Observable<any>,
		state$: StateObservable<RootState>
	): Observable<any> => {
		return action$.pipe(
			ofType(SearchActions.SET_SEARCH_TERM),
			mergeMap(_action => {
				const { payload } = _action;
				let newSortOptions: ISortByOption[] | undefined = [];
				let sortBySelected: ISortByOption | undefined = undefined;
				if (payload) {
					const filterState = state$.value.appState.filterState;
					const mostRelevantSortOption: ISortByOption = {
						id: uuidv4(),
						name: 'Most Relevant',
						sortType: sortOptionType.MOST_RELEVANT,
						productSort: {
							attribute: 'MostRelevant',
							order: ['ASC']
						}
					};
					const sortByOptions = filterState?.sortByOptions;
					const mostRelevantOption = sortByOptions?.find(
						(option: ISortByOption) => option.sortType === sortOptionType.MOST_RELEVANT
					);
					if (!mostRelevantOption) {
						sortByOptions?.unshift(mostRelevantSortOption);
						newSortOptions = sortByOptions;
						sortBySelected = mostRelevantSortOption;
					} else {
						newSortOptions = sortByOptions;
						sortBySelected = mostRelevantOption;
					}
				} else {
					const filterState = state$.value.appState.filterState;
					const sortByOptions = filterState?.sortByOptions;
					const mostRelevantOption = sortByOptions?.find(
						(option: ISortByOption) => option.sortType === sortOptionType.MOST_RELEVANT
					);
					if (mostRelevantOption) {
						sortByOptions?.shift();
						newSortOptions = sortByOptions;
						if (filterState?.sortBySelected?.sortType === sortOptionType.MOST_RELEVANT) {
							sortBySelected = sortByOptions?.find(
								(option: ISortByOption) => option.sortType === sortOptionType.ALPHABETICAL
							);
						} else {
							sortBySelected = filterState?.sortBySelected;
						}
					} else {
						newSortOptions = sortByOptions;
						sortBySelected = filterState?.sortBySelected;
					}
				}
				return of(
					SearchActions.setSearchTermSuccess({
						searchTerm: payload,
						sortByOptions: newSortOptions,
						sortBySelected
					})
				);
			}),
			catchError(() => of(SearchActions.SET_SEARCH_TERM_ERROR))
		);
	};
}
