import { useEffect, useState } from 'react';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import React from 'react';
import './ProductDetailsFormComponent.scss';
import { Product } from '../../../../common/model';
import { getIn, useFormik } from 'formik';
import * as Yup from 'yup';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';
import { validationMessages } from '../../../../common/constants';
import {
	validateExactLengthMessage,
	validateMessageMax
} from '../../../../helpers/formFieldValidations';
interface IProductDetailsForm {
	productState: any;
	onUpdateProfileProduct: (userState: any) => void;
	onCancel: () => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
}
interface IDimensions {
	height: string;
	width: string;
	depth: string;
	weight: string;
}
const ProductDetailFormSchema = Yup.object().shape({
	flagshipProduct: Yup.boolean().required(validationMessages.field_required),
	product_width: Yup.number().required(validationMessages.field_required),
	dimensions: Yup.object().shape({
		height: Yup.string().test(
			'length',
			validateExactLengthMessage('3 digits'),
			val => val !== undefined && val?.toString().length === 3
		),
		width: Yup.string().test(
			'length',
			validateExactLengthMessage('3 digits'),
			val => val !== undefined && val?.toString().length === 3
		),
		depth: Yup.string().test(
			'length',
			validateExactLengthMessage('3 digits'),
			val => val !== undefined && val?.toString().length === 3
		),
		weight: Yup.string().test(
			'length',
			validateExactLengthMessage('3 digits'),
			val => val !== undefined && val?.toString().length === 3
		)
	}),
	productGTIN: Yup.string().test(
		'length',
		validateExactLengthMessage('13 digits'),
		val => val !== undefined && val?.toString().length === 13
	),
	launchYear: Yup.number()
		.test(
			'length',
			validateExactLengthMessage('4 digits'),
			val => val !== undefined && val?.toString().length === 4
		)
		.max(new Date().getFullYear(), validateMessageMax(new Date().getFullYear().toString())),
	productShelfLife: Yup.number().required(validationMessages.field_required)
});
const ProductDetailsFormComponent: React.FC<IProductDetailsForm> = ({
	productState,
	onUpdateProfileProduct,
	onCancel,
	isEditing,
	toggleEditing
}) => {
	const [state, setState] = useState<Product>();
	const [ID, setID] = useState<string | null>(null);

	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};

	const dimensionsProducts: IDimensions = {
		height: '',
		width: '',
		depth: '',
		weight: ''
	};

	const { errors, values, handleChange, handleReset, submitForm, setFieldValue, isValid } =
		useFormik({
			// Update this values with product response
			initialValues: {
				flagshipProduct: false,
				dimensions: dimensionsProducts,
				productMSRP: 0,
				productGTIN: '',
				launchYear: '',
				productShelfLife: 0
			},
			onSubmit: values => {
				console.log(values);
			},
			validationSchema: ProductDetailFormSchema
		});

	useEffect(() => {
		if (productState) {
			setState(productState);
		}
	}, [productState]);
	useEffect(() => {
		if (state) {
			setID('product-details' + state.id);
		}
	}, [state]);
	return (
		<div className='product-details-form'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => {
					onUpdateProfileProduct(state);
				}}
				titleText='Product Details'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms
											text="Is This your Brand's Flagship Product?
											"
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Your Flagship product is a best-seller or fan favorite product from your
													brand. For Newtopia Now, this product is featured in The Market.
												</>
											}
											toolTipPosition='right'
										></LabelForms>
										<p>Yes</p>
										{/* <ButtonComponent
											onClick={() => {}}
											style='rounded-button tertiary toggle-button'
											text=''
											icon={<i className='ri-toggle-fill' />}
											disabled
										/> */}
									</div>
									<div>
										<LabelForms text='Product Dimensions'></LabelForms>
										<p>Width (inches): 12</p>
										<p>Length (inches): 12</p>
										<p>Height (inches): 12</p>
										<p>Weight (ounces): 12</p>
									</div>
									<div>
										<LabelForms
											text='Product MSRP'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Manufacturer's Suggested Retail Price - also known as list price or
													recommended retail price.
												</>
											}
										></LabelForms>
										<p>$4.87</p>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Launch Year of the Product'></LabelForms>
										<p>2023</p>
									</div>
									<div>
										<LabelForms
											text='Parent GTIN'
											toolTipText={
												<>
													A Global Trade Item Number is a unique and internationally recognized
													identifier for a product. When available, it will appear next to the
													barcode on your product's packaging.
												</>
											}
											icon={<i className='ri-question-line' />}
										></LabelForms>
										<p>4534063856837262</p>
									</div>
									<div>
										<LabelForms text='Product Shelf Life (days)'></LabelForms>
										<p>12</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<ContainerForms
							FormComponent1={
								<>
									{' '}
									<div>
										<LabelForms
											text="Is this your brand's Flagship Product?"
											toolTipText={
												<>
													Your Flagship product is a best-seller or fan favorite product from your
													brand. For Newtopia Now, this product is featured in The Market.
												</>
											}
											icon={<i className='ri-question-line'></i>}
										></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='flagshipProduct'
											value={values.flagshipProduct}
											disabled={false}
											onChange={handleChange}
										></CheckboxFormComponent>
									</div>
									<div>
										<LabelForms
											text='Product MSRP'
											icon={<i className='ri-question-line' />}
											toolTipText={
												<>
													Manufacturer's Suggested Retail Price - also known as list price or
													recommended retail price.
												</>
											}
										></LabelForms>
										<TextInputComponent
											error={errors.productMSRP?.length! > 0}
											errorHelperText={<div>{errors.productMSRP}!</div>}
											placeholder='Product MSRP'
											name='productMSRP'
											onChange={handleChange}
											value={values.productMSRP!}
										></TextInputComponent>
									</div>
									<div>
										<LabelForms
											text='Parent GTIN'
											toolTipText={
												<>
													A Global Trade Item Number is a unique and internationally recognized
													identifier for a product. When available, it will appear next to the
													barcode on your product's packaging.
												</>
											}
											icon={<i className='ri-question-line' />}
										></LabelForms>
										<TextInputComponent
											error={errors.productGTIN?.length! > 0}
											errorHelperText={<div>{errors.productGTIN}!</div>}
											placeholder='Parent GTIN'
											name='productGTIN'
											onChange={handleChange}
											value={values.productGTIN!}
										></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Launch Year of the Product'></LabelForms>
										<TextInputComponent
											error={errors.launchYear?.length! > 0}
											errorHelperText={<div>{errors.launchYear}!</div>}
											placeholder='Launch Year of the Product'
											name='launchYear'
											onChange={handleChange}
											value={values.launchYear!}
										></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Product Shelf Life (days)'></LabelForms>
										<TextInputComponent
											error={errors.productShelfLife?.length! > 0}
											errorHelperText={<div>{errors.productShelfLife}!</div>}
											placeholder='Product Shelf Life (days)'
											name='productShelfLife'
											onChange={handleChange}
											value={values.productShelfLife!}
										></TextInputComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									{' '}
									<div>
										<LabelForms text='Product Dimensions'></LabelForms>
									</div>
									{Object.keys(values.dimensions!).map(key => (
										<div key={key}>
											<LabelForms
												text={`${key} ${key === 'weight' ? '(ounces)' : '(inches)'}`}
											></LabelForms>
											<TextInputComponent
												error={getIn(errors, `dimensions.${key}`) !== undefined}
												errorHelperText={<div>{getIn(errors, `dimensions.${key}`)}!</div>}
												placeholder={`${key} ${key === 'weight' ? '(ounces)' : '(inches)'}`}
												name={`dimensions.${key}`}
												onChange={handleChange}
												value={values.dimensions![key as keyof typeof dimensionsProducts]}
											></TextInputComponent>
										</div>
									))}
								</>
							}
						></ContainerForms>
						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(ProductDetailsFormComponent);
