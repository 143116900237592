import React from 'react';
import { UserProfileData } from '../../../authentication/user-profile/model';
import TitleForms from '../../GenericBaseForms/TitleForms/TitleForms';
import LabelForms from '../../GenericBaseForms/LabelForms/LabelForms';
import './FinancialFormComponent.scss';
import { useEffect, useState } from 'react';
import { ContainerForms } from '../../GenericBaseForms/ContainerForms/ContainerForms';
import TextInputComponent from '../../GenericBaseForms/TextInputComponent/TextInputComponent';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ActionsForms } from '../../GenericBaseForms/ActionsForms/ActionsForms';
import CheckboxFormComponent from '../../GenericBaseForms/CheckboxFormComponent/CheckboxFormComponent';

interface IFinancialForm {
	brandState: any;
	onUpdateProfileBrand: (userState: any) => void;
	isEditing: string | null;
	toggleEditing: (id: string | null) => void;
	onCancel: () => void;
}
const BrandFormSchema = Yup.object().shape({
	activelyRaisingCapitalC: Yup.boolean(),
	revenueLastYearC: Yup.number(),
	revenueExpectedThisYearC: Yup.number()
});

const FinancialFormComponent: React.FC<IFinancialForm> = ({
	brandState,
	onUpdateProfileBrand,
	isEditing,
	toggleEditing,
	onCancel
}) => {
	const [state, setState] = useState<UserProfileData>();
	const ID = 'financial-details';
	const [isVisible, setVisibility] = useState<boolean>(true);

	const toggleVisibility = () => {
		setVisibility(!isVisible);
	};
	useEffect(() => {
		if (brandState) {
			setState(brandState);
		}
	}, [brandState]);

	const { errors, values, handleChange, handleReset, submitForm, setFieldValue, isValid } =
		useFormik({
			initialValues: {
				activelyRaisingCapitalC: false,
				revenueLastYearC: 0,
				revenueExpectedThisYearC: 0
			},
			onSubmit: values => {
				onUpdateProfileBrand('');
			},
			validationSchema: BrandFormSchema
		});

	return (
		<div className='financial-tab'>
			<TitleForms
				isEditing={isEditing === ID}
				isVisible={isVisible}
				onUpdate={() => {}}
				titleText='Financial Info'
				toggleEditing={() => toggleEditing(ID)}
				toggleVisibility={toggleVisibility}
			></TitleForms>
			{isVisible &&
				(isEditing !== ID ? (
					<>
						<p className='description-form'>
							Responses will be shared only with select registered Expo West/Newtopia Now investors
							and Nutrition Capital Network’s investor network.
						</p>
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms text='Are you Actively Raising Capital?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='activelyRaisingCapitalC'
											value={values.activelyRaisingCapitalC!}
											disabled={true}
											onChange={handleChange}
										></CheckboxFormComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='Revenue Last Year (in US dollars)'></LabelForms>
										<p>$2,805,913.74</p>
									</div>
									<div>
										<LabelForms text='Revenue Expected This Year (in US dollars)'></LabelForms>
										<p>$3,250,000.00</p>
									</div>
								</>
							}
						></ContainerForms>
					</>
				) : (
					<>
						<p className='description-form'>
							Responses will be shared only with select registered Expo West/Newtopia Now investors
							and Nutrition Capital Network’s investor network.
						</p>
						<ContainerForms
							FormComponent1={
								<>
									<div>
										<LabelForms text='Are you Actively Raising Capital?'></LabelForms>
										<CheckboxFormComponent
											checkboxType='toggle'
											name='activelyRaisingCapitalC'
											value={values.activelyRaisingCapitalC!}
											disabled={false}
											onChange={handleChange}
										></CheckboxFormComponent>
									</div>
								</>
							}
							FormComponent2={
								<>
									<div>
										<LabelForms text='Revenue Last Year (in US dollars)'></LabelForms>
										<TextInputComponent
											error={errors.revenueLastYearC?.length! > 0}
											errorHelperText={<div>{errors.revenueLastYearC}!</div>}
											placeholder='Revenue Last Year (in US dollars)'
											name='revenueLastYearC'
											onChange={handleChange}
											value={values.revenueLastYearC!}
											type='number'
										></TextInputComponent>
									</div>
									<div>
										<LabelForms text='Revenue Expected This Year (in US dollars)'></LabelForms>
										<TextInputComponent
											error={errors.revenueExpectedThisYearC?.length! > 0}
											errorHelperText={<div>{errors.revenueExpectedThisYearC}!</div>}
											placeholder='Revenue Expected This Year (in US dollars)'
											name='revenueExpectedThisYearC'
											onChange={handleChange}
											value={values.revenueExpectedThisYearC!}
											type='number'
										></TextInputComponent>
									</div>
								</>
							}
						></ContainerForms>

						<ActionsForms
							onUpdate={() => submitForm()}
							onCancel={() => {
								onCancel();
								handleReset(null);
							}}
							isValid={isValid}
							toggleEditing={() => toggleEditing(null)}
						></ActionsForms>
					</>
				))}
		</div>
	);
};

export default React.memo(FinancialFormComponent);
