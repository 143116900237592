import { FC } from 'react';
import './OnboardHeaderComponent.scss';
interface IOnboardHeaderComponentProps {
	title: string;
	children: JSX.Element;
}

export const OnboardHeaderComponent: FC<IOnboardHeaderComponentProps> = ({ title, children }) => {
	return (
		<div className='header-container'>
			<div className='icon-container'>
				<i className='ri-error-warning-fill'></i>
			</div>
			<div className='onboard-content-container'>
				<div className='header-title-container'>
					<span className='header-title'>{title}</span>
				</div>
				<div className='children-container'>{children}</div>
			</div>
		</div>
	);
};
